import React from "react";
import withStyles from "@material-ui/core/styles/withStyles";
import footerStyle from "../../assets/jss/material-dashboard-react/components/footerStyle.jsx";
import LazyLoad from "react-lazyload";
import { useSelector, shallowEqual } from "react-redux";
import ReactWhatsappButton from "react-whatsapp-button";
import { RiFacebookLine } from "react-icons/ri";
import { CiInstagram } from "react-icons/ci";
import { CiYoutube } from "react-icons/ci";
import { PiDiscordLogoThin } from "react-icons/pi";
import { FaWhatsapp } from "react-icons/fa";
import { RiLinkedinLine } from "react-icons/ri";
import { RiTwitterXLine } from "react-icons/ri";





import { CiPhone, CiMail } from "react-icons/ci";


import "./Footer.css"

function Footer() {
  const isMobileState = useSelector(
    state => state.mainReducer.isMobile,
    shallowEqual
  );
  return (
    <>
     
      <section id="page-need_guidance-section" class="need-guidance-form flex items-end md:items-center md:min-h-[680px] lg:min-h-[800px] relative z-[11] -mt-[2px] overflow-hidden rammm" style={{ minHeight: window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-gaming-courses-test/"  ?"100px":"370px" }} data-locomotive-section-id="page-need_guidance" data-locomotive-section-type="need_guidance">


        <div class="min-h-full h-full w-full container-full1 relative z-[3] py-4">
          <div class="sm:max-w-[480px] lg:max-w-[560px]" style={{ maxWidth: "100%" }}>

            <div class="">








              <div className="grid-cols-1 sm:grid-cols-2 gap-3 mt-3 sm:mt-5 " style={{ width: "100%" }}>








                <div className={window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  ? "testfooter" : "footer-wrapper"}>
                  {isMobileState ? <div className="bottomFoot sticky-footer" style={{ display : window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  ? "none" : "block" }}>
                    <div className="mobile-sticky-footer ">
                      <div className="bottomFootLeft"><a target="_blank" rel="noopener noreferrer" href="tel:+918008002795"><CiPhone /> Call Now</a></div>
                      <div className="bottomFootRight"><a target="_blank" rel="noopener noreferrer" aria-label="Chat on WhatsApp" href="https://wa.me/918008002795"><FaWhatsapp /> Whatsapp</a></div>
                    </div>
                  </div> : null}

                  {isMobileState ? null : <div className="floatingIcon" style={{ display : window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  ? "none" : "block" }}>
                    <div className="App">
                      <ReactWhatsappButton
                        countryCode="91"
                        phoneNumber="8008002795"
                        animated
                        style={{ right: "2rem" }}
                        className="whatsappsivak"
                      />
                      <a href="tel:+918008002795" className="btn-floating-whatsapp durga btn-floating-whatsapp__animated" style={{ right: isMobileState ? "10rem" : "8rem", backgroundColor: "rgb(0, 164, 245)", backgroundPosition: "center center", backgroundRepeat: "no-repeat", backgroundSize: "contain", backgroundSize: '40%' }}> </a>
                    </div>
                  </div>}

               {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" ? null :  <div className="foot-details">
                    <LazyLoad height={76} offset={100} fadein={true}>
                      <div className="foot-item fadeInUp anime-delay3">
                        <h3 className="footer-title">Quick Links</h3>
                        <div className="contact-wrapper">
                          <div className="contact-wrapper-inner" style={{ display : window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  ? "none" : "block" }}>

                            <div>
                              <p>Blogs</p>
                            </div>
                          </div>
                          <div className="contact-wrapper-inner" style={{ display : window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  ? "none" : "block" }}>

                            <div>
                              <p>Brochure</p>
                            </div>
                          </div>
                          <div className="contact-wrapper-inner" style={{ display : window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/"  ? "none" : "block" }}>

                            <div>
                              <p>E-book</p>
                            </div>
                          </div>
                          <div className="contact-wrapper-inner">

                            <div>
                              <p>Why the Gaming Industry?</p>
                            </div>
                          </div>
                        </div>
                      </div>

                    </LazyLoad>
                  </div> }
                  {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" ? null : <div className="foot-details">
                    <LazyLoad height={76} offset={100} fadein={true}>

                      <div className="foot-item fadeInUp anime-delay3">
                        <h3 className="footer-title">Contact Info</h3>
                        <div className="contact-wrapper">
                          <div className="cont1">
                          <div className="contact-wrapper-inner">
                           
                            <CiPhone />
                            <div>
                              <p ><a href="tel:8008002794" rel="noopener noreferrer"> +91-8008002794 </a></p>
                              
                              </div>
                          </div>
                          <div className="contact-wrapper-inner">
                            <CiPhone />
                            <div>
                            <p ><a href="tel:8008002795" rel="noopener noreferrer"> +91-8008002795 </a></p>

                            </div>
                          </div>
</div>
                          <div className="contact-wrapper-inner">
                            <CiMail />
                            <div>
                              <p><a href="mailto:info@backstagepass.co.in" rel="noopener noreferrer">info@backstagepass.co.in</a></p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </LazyLoad>
                  </div> }

                  {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" ?    <div className="foot-details">
                    <LazyLoad height={76} offset={100} fadein={true}>

                      <div className="foot-item fadeInUp anime-delay3">
                        <h3 className="footer-title">Contact Info</h3>
                        <div className="contact-wrapper">

                          <div className="contact-wrapper-inner">
                           
                            <div className="fotterh">
                            
                              <CiPhone className="icon" /><p> <a href="tel:8008002794" className="phone-number" rel="noopener noreferrer"> +91-8008002794</a></p>
                              </div>
                          </div>
                          <div className="contact-wrapper-inner">
                           
                            <div  className="fotterh">
                            <CiPhone className="icon" /> 
                              <p> <a href="tel:8008002795" className="phone-number" rel="noopener noreferrer"> +91-8008002795</a></p>
                             
                            </div>
                          </div>

                         
                        </div>
                      </div>
                    </LazyLoad>
                  </div> : null }


                </div>
              </div>
            </div> 
          
           
            <div>
            
              <LazyLoad height={76} offset={100} fadein={true}>
                <div className="foot-item fadeInUp anime-delay3">
                {window.location.pathname === "/landingpage/diploma-and-advanced-diploma-courses/" || window.location.pathname === "/landingpage/diploma-and-advanced-diploma-game-development-courses/" ? null :
                <div className="contact-wrapper ftr">
                    <div className="contact-wrapper-inner md1">
                      <a href="https://discord.com/invite/fTYuzCJnsy" target="_blank" rel="noopener noreferrer"><PiDiscordLogoThin /></a>


                    </div>
                    <div className="contact-wrapper-inner md2">
                      <a href="https://www.instagram.com/backstagepassgaming/" target="_blank" rel="noopener noreferrer"><CiInstagram /></a>

                    </div>
                    <div className="contact-wrapper-inner md3">
                      <a href="https://www.facebook.com/bspgaminginstitute/" target="_blank" rel="noopener noreferrer"><RiFacebookLine /></a>

                    </div>
                    <div className="contact-wrapper-inner md4">
                      <a href="https://www.youtube.com/channel/UC8-3EcO9JQO8cRJklp8Gdvg" target="_blank" rel="noopener noreferrer"><CiYoutube /></a>

                    </div>
                 
                    <div className="contact-wrapper-inner md4">
                    <a href="https://x.com/Bsp_Gaming" target="_blank"><RiTwitterXLine /></a>
                    </div>
                    <div className="contact-wrapper-inner md4">
                    <a href="https://www.linkedin.com/school/backstagepassinstitute/" target="_blank"><RiLinkedinLine />
                    </a>

                    </div>
                  </div> }
                  <LazyLoad height={76} offset={100} fadein={true}>
                    <div className="text-center foot-content fadeInUp anime-delay2">© 2024 All Rights Reserved</div>
                  </LazyLoad>
                </div>
              </LazyLoad>
            </div> 














          </div>
        </div>
      </section>
    </>
    //     <footer className="page-footer">
    //       <div className="footer-container">
    //         <div className="row">
    //           <div className="footer-wrapper">

    //             <div className="floatingIcon">
    //             <FloatingButton backgroundColor="#ffffff" color="#ffffff" className="sivatet">
    //   <Item
    //     imgSrc={"https://cdn3.iconfinder.com/data/icons/social-networks-34/96/social-18-256.png"}
    //     onClick={() => {
    //       console.log("callback function here");
    //     }}

    //   />
    //   <Item
    //     imgSrc={"https://cdn1.iconfinder.com/data/icons/multimedia-and-interface-flat-style-1/32/Multimedia_Telephone_call_contact_phone_ringing_multimedia-64.png"}
    //     onClick={() => {
    //       console.log("callback function here");
    //     }}

    //   />
    // </FloatingButton>
    // </div>

    //             <div className="foot-details">
    //               <LazyLoad height={76} offset={100} fadein={true}>
    //               <div className="foot-item fadeInUp anime-delay3">
    //                   <h3 className="footer-title">Quick Links</h3>
    //                   <div className="contact-wrapper">
    //                     <div className="contact-wrapper-inner">

    //                       <div>
    //                         <p><a href="">Blogs</a></p>
    //                       </div>
    //                     </div>
    //                     <div className="contact-wrapper-inner">

    //                       <div>
    //                         <p><a href="">Brochure</a></p>
    //                       </div>
    //                     </div>
    //                     <div className="contact-wrapper-inner">

    //                       <div>
    //                         <p><a href="mailto:info@backstagepass.co.in">E-book</a></p>
    //                       </div>
    //                     </div>
    //                     <div className="contact-wrapper-inner">

    //                       <div>
    //                         <p><a href="mailto:info@backstagepass.co.in">Why the Gaming Industry?</a></p>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>

    //               </LazyLoad>
    //             </div>
    //             <div className="foot-details">
    //               <LazyLoad height={76} offset={100} fadein={true}>

    //                 <div className="foot-item fadeInUp anime-delay3">
    //                   <h3 className="footer-title">Contact Info</h3>
    //                   <div className="contact-wrapper">

    //                     <div className="contact-wrapper-inner">
    //                       <CiPhone />
    //                       <div>
    //                         <p><a href="tel:8977945702">+91-8008002794</a></p>
    //                       </div>
    //                     </div>
    //                     <div className="contact-wrapper-inner">
    //                       <CiPhone />
    //                       <div>
    //                         <p><a href="tel:8977945702">+91-8008002795</a></p>
    //                       </div>
    //                     </div>
    //                     <div className="contact-wrapper-inner">
    //                       <CiMail  />
    //                       <div>
    //                         <p><a href="mailto:info@backstagepass.co.in">info@backstagepass.co.in</a></p>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //               </LazyLoad>
    //             </div>
    //           </div>
    //         </div>
    //       </div>
    //       <div>
    //               <LazyLoad height={76} offset={100} fadein={true}>
    //               <div className="foot-item fadeInUp anime-delay3">
    //                   <div className="contact-wrapper ftr">
    //                   <div className="contact-wrapper-inner md1">
    //                       <img src={discord} />

    //                     </div>
    //                     <div className="contact-wrapper-inner md2">
    //                     <img src={instagram} />

    //                     </div>
    //                     <div className="contact-wrapper-inner md3">
    //                       <img src={facebook}  />

    //                     </div>
    //                     <div className="contact-wrapper-inner md4">
    //                     <img src={youtube} />

    //                     </div>
    //                     <div className="contact-wrapper-inner md4">
    //                     <img src={tweet} />

    //                     </div>

    //                   </div>
    //                   <LazyLoad height={76} offset={100} fadein={true}>
    //               <div className="text-center foot-content fadeInUp anime-delay2">© 2024 All Rights Reserved</div>
    //             </LazyLoad>
    //                 </div>
    //               </LazyLoad>
    //             </div>

    //     </footer>
  );
}

export default withStyles(footerStyle)(Footer);
